.overlay {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  z-index: 11111;

  .popup {
    padding: 55px 10px 0;
    max-width: 633px;
    width: 100%;
    max-height: 528px;
    position: relative;
    border-radius: 10px;
    border: 0.867px solid #fff;
    background: #171717;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 20px 0;

    .closeButton {
      position: absolute;
      top: 14px;
      right: 23px;
      cursor: pointer;
      font-size: 20px;
      color: #333;
    }

    .logo {
      width: 151px;
    }

    h2 {
      text-align: center;
      background: var(
        --btcpad,
        linear-gradient(180deg, #fae485 0%, #f47115 27.51%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bulletList {
      margin: 20px 0 28px;
      padding-left: 20px;
      list-style-type: none;

      li {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        padding-left: 20px;
        max-width: 376px;
        text-align: start;
        margin-bottom: 23px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background: var(
            --btcpad,
            linear-gradient(180deg, #fae485 0%, #f47115 27.51%)
          );
        }
      }
    }

    .whitelistSection {
      display: flex;
      gap: 5px;

      img {
        width: 36px;
        height: 36px;
      }

      .whitelistButton {
        color: #fa8943;
        font-family: Inter;
        font-size: 26px;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .popup {
      margin-top: 10px;
      padding-top: 40px;
      max-width: 583px;

      .whitelistSection {
        img {
          width: 20px;
          height: 20px;
        }
        .whitelistButton {
          font-size: 18px;
        }
      }

      .bulletList {
        margin: 40x 0;

        li {
          margin-bottom: 15px;
        }
      }

      .logo {
        width: 120px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .popup {
      .bulletList {
        margin: 15px 0;
      }

      .whitelistSection {
        img {
          width: 20px;
          height: 20px;
        }
        .whitelistButton {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-height: 740px) {
    .popup {
      max-height: 500px;
      margin-top: 30px;
    }
  }
}
