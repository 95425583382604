.wrapper {
  border-top: 1px solid #dedfe1;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 23px;
  padding-bottom: 35px;

  position: relative;
  margin: 0;
  color: white;

  .topSection {
    display: flex;
    justify-content: space-between;

    .logo {
      img {
        width: 154px;
        cursor: pointer;
      }
    }

    .socialIcons {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      img {
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }
  }

  p {
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    gap: 22px;
    padding-bottom: 22px;
  }
}
