.headTitle {
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 113px 0 188px;
  position: relative;

  .roundStar {
    position: absolute;
    width: 60px;
    top: 20%;
    left: 0%;
  }

  .sqareStar {
    position: absolute;
    width: 80px;
    top: 35%;
    left: 2%;
    video {
      width: 100%;
    }
  }

  div {
    color: var(--black-black-100, #fff);
    text-align: center;
    font-family: JunkyardCalibo;
    font-size: 48px;
    font-weight: 400;
    line-height: 130%;
    max-width: 780px;
    text-align: center;
  }

  p {
    color: #ffffff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    max-width: 580px;
  }

  @media screen and (max-width: 1250px) {
    div {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1030px) {
    div {
      font-size: 35px;
      max-width: 580px;
    }
  }

  @media screen and (max-width: 960px) {
    div {
      font-size: 30px;
      max-width: 580px;
    }
  }

  @media screen and (max-width: 768px) {
    div {
      font-size: 24px;
      max-width: 374px;
    }
    p {
      font-size: 14px;
      max-width: 374px;
    }
    .roundStar {
      width: 30px;
      top: 5%;
    }

    .sqareStar {
      width: 41px;
      top: 10%;
      left: 2%;
    }
  }

  @media screen and (max-width: 1250px) {
    padding-bottom: 160px;
  }
  @media screen and (max-width: 1040px) {
    padding-bottom: 140px;
  }
  @media screen and (max-width: 960px) {
    padding-bottom: 120px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 41px;
    padding-top: 79px;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 255px;
  padding: 0 33px 162px;
  color: var(--black-black-100, #fff);
  justify-content: center;
  align-items: center;

  .card {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1200px;
    align-items: center;
    gap: 25px;

    h2 {
      color: var(--black-black-100, #fff);
      font-family: JunkyardCalibo;
      font-size: 45px;
      font-weight: 400;
    }

    p,
    li {
      max-width: 580px;
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 150%;
    }

    .cardImageWrapper {
      position: relative;

      .buildersImg {
        width: 400px;
        video {
          width: 100%;
        }
      }
      .buildersGif1 {
        position: absolute;
        width: 50px;
        top: 25%;
        left: 75%;
        video {
          width: 100%;
        }
      }
      .buildersGif2 {
        position: absolute;
        width: 75px;
        top: 50%;
        left: 60%;
        video {
          width: 100%;
        }
      }
      .buildersGif3 {
        position: absolute;
        width: 30px;
        top: 60%;
        left: 90%;
        video {
          width: 100%;
        }
      }
      .buildersGif4 {
        position: absolute;
        width: 58px;
        top: 35%;
        left: 85%;
      }

      .investorsImg {
        width: 450px;
      }

      .protocolsImg {
        width: 200px;
        padding: 0 37px;
      }
      .protocolsGif1 {
        position: absolute;
        width: 300px;
        top: 0%;
        left: 15%;
        video {
          width: 100%;
        }
      }

      .infrastructureImg {
        width: 450px;
      }

      .communityImg {
        width: 450px;
      }
      .communityGif1 {
        position: absolute;
        width: 28px;
        top: 28%;
        left: 7%;
        video {
          width: 100%;
        }
      }
      .communityGif2 {
        position: absolute;
        width: 28px;
        top: 28%;
        left: 85%;
        video {
          width: 100%;
        }
      }

      @media screen and (max-width: 1250px) {
        .buildersImg {
          width: 350px;
        }
        .buildersGif1 {
          width: 40px;
        }
        .buildersGif2 {
          width: 60px;
        }
        .buildersGif3 {
          width: 30px;
        }
        .buildersGif4 {
          width: 48px;
        }
        .investorsImg {
          width: 380px;
        }
        .protocolsImg {
          width: 170px;
        }
        .protocolsGif1 {
          width: 250px;
        }
        .infrastructureImg {
          width: 380px;
        }
        .communityImg {
          width: 380px;
        }
      }

      @media screen and (max-width: 1030px) {
        .buildersImg {
          width: 300px;
        }
        .investorsImg {
          width: 320px;
        }
        .protocolsImg {
          width: 150px;
        }
        .protocolsGif1 {
          width: 250px;
        }
        .infrastructureImg {
          width: 320px;
        }
        .communityImg {
          width: 320px;
        }
      }

      @media screen and (max-width: 960px) {
        .buildersImg {
          width: 250px;
        }
        .buildersGif1 {
          width: 30px;
          left: 90%;
        }
        .buildersGif2 {
          width: 40px;
          left: 80%;
        }
        .buildersGif3 {
          width: 20px;
        }
        .buildersGif4 {
          width: 35px;
          left: 95%;
        }
        .investorsImg {
          width: 290px;
        }
        .protocolsImg {
          width: 150px;
        }
        .protocolsGif1 {
          width: 200px;
        }
        .infrastructureImg {
          max-width: 290px;
        }
        .communityImg {
          width: 290px;
        }
        .communityGif1 {
          width: 16px;
          top: 30%;
          left: 7%;
        }
        .communityGif2 {
          width: 16px;
          top: 30%;
          left: 85%;
        }
      }

      @media screen and (max-width: 768px) {
        .buildersImg {
          width: 300px;
        }
      }
    }

    @media screen and (max-width: 1250px) {
      p,
      li {
        max-width: 500px;
        font-size: 18px;
      }
      h2 {
        font-size: 40px;
      }
    }

    @media screen and (max-width: 1030px) {
      p,
      li {
        max-width: 500px;
        font-size: 18px;
      }
      h2 {
        font-size: 35px;
      }
    }

    @media screen and (max-width: 960px) {
      p,
      li {
        max-width: 530px;
      }
      h2 {
        font-size: 30px;
      }
    }
    @media screen and (max-width: 600px) {
      p,
      li {
        max-width: 400px;
        font-size: 16px;
      }
    }
    @media screen and (max-width: 1250px) {
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    .card {
      flex-wrap: wrap-reverse;
      justify-content: center;

      h2 {
        font-size: 22px;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    gap: 200px;
  }
  @media screen and (max-width: 1140px) {
    gap: 150px;
  }
  @media screen and (max-width: 960px) {
    gap: 120px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 73px;
  }
}

.chartTitle {
  color: var(--black-black-100, #fff);
  font-family: JunkyardCalibo;
  font-size: 45px;
  font-weight: 400;
  padding: 87px 0 163px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    padding: 62px 0 45px;
  }
}

.chartWrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 975px;
  margin: 0 auto;
  gap: 30px;
  padding-bottom: 116px;

  @media screen and (max-width: 1040px) {
    justify-content: space-between;
    gap: 10px;
  }

  @media screen and (max-width: 878px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .chartContainer {
    padding: 0 24px 24px 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    border-radius: 16px;
    border: 1px solid rgba(210, 210, 210, 0.5);
    box-shadow: 0px 3px 6px 0px rgba(217, 217, 217, 0.1),
      0px 10px 10px 0px rgba(217, 217, 217, 0.09),
      0px 23px 14px 0px rgba(217, 217, 217, 0.05),
      0px 40px 16px 0px rgba(217, 217, 217, 0.01),
      0px 63px 18px 0px rgba(217, 217, 217, 0);

    .supply {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04px;
      padding: 24px 0 16px;
      border-bottom: 1px solid rgba(210, 210, 210, 0.5);
    }

    .community {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04px;
      padding: 0 0 10px;
    }

    @media screen and (max-width: 768px) {
      padding: 0 0 24px;
      align-items: center;

      .supply {
        margin: 0 24px;
      }
      .community {
        margin: 0 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 81px;
  }
}
