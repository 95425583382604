.editorWrapper {
  width: 100%;
}

.toolbarClassName {
  border: none;
  font-family: Inter;
  background-color: #171717;
  color: white;

  div {
    background-color: #171717;
    border: none;
    box-shadow: none !important;
    transform: none !important;
    padding: 0;
  }

  input {
    width: 90% !important;
  }

  button {
    border-radius: 10px;
    border: 1px solid var(--f, rgba(255, 255, 255, 0.47));
    background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
    color: #fff;
    width: 100px;
    font-family: JunkyardCalibo;
    font-weight: 400;
    cursor: pointer;
  }
}

.wrapperClassName {
  border: 1px solid #f5841f;
  flex-direction: column-reverse;
  justify-content: space-between;
  border-radius: 4px;
  display: flex;
  min-height: 165px;
  width: 100%;

  a {
    color: #f5841f !important;
  }
}

.editorClassName {
  font-family: Inter;
  color: #ffffff;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.customListDropdown .rdw-option-wrapper:nth-child(1),
.customListDropdown .rdw-option-wrapper:nth-child(3),
.customListDropdown .rdw-option-wrapper:nth-child(4) {
  display: none;
}

.customLinkToolbar .rdw-option-wrapper:nth-child(2) {
  display: none;
}
