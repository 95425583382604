.mobileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-bottom: 80px;
}

.mobileSlide {
  max-width: 380px;
  border-radius: 25px;
  background: var(--black-black-12, #1a1b23);
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 22px 15px;
  text-align: center;

  .title {
    color: var(--black-black-100, #fff);
    font-family: JunkyardCalibo;
    font-size: 22px;
    font-weight: 400;
  }

  .description {
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    max-width: 332px;
    width: 100%;
    height: 72px;
  }

  @media screen and (max-width: 768px) {
    .description {
      max-width: 309px;
    }
    .title {
      font-size: 20px;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-380px * 5));
  }
}

.container1 {
  display: grid;
  place-items: center;
}

.slider1 {
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;

  .slideTrack {
    display: flex;
    width: calc(380px * 10);
    animation: scroll 20s linear infinite;

    .swiperSlide {
      margin: 0 15px;
      width: 380px;
      border-radius: 25px;
      background: var(--black-black-12, #1a1b23);
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      justify-content: center;
      padding: 32px 0;
      text-align: center;

      .title {
        color: var(--black-black-100, #fff);
        font-family: JunkyardCalibo;
        font-size: 22px;
        font-weight: 400;
      }

      .description {
        color: #ffffff;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        max-width: 332px;
        width: 100%;
        height: 72px;
      }
    }

    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-380px * 5));
      }
    }
  }
}
