.customTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.customTableHeaderRow {
  border: 1px solid black;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.customTableHeaderCell {
  border: 1px solid white;
  padding: 1rem;
  font-weight: bold;
  color: white;
}

.customTableRow {
  border: 1px solid white;
}

.customTableTotalRow {
  border: 1px solid white;
  font-weight: bold;
  color: black;
}

.customTableCell {
  border: 1px solid white;
  padding: 1rem;
  font-size: 14px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.customTableTotalCell {
  font-weight: bold;
  color: black;
}
