.container {
  display: flex;

  .imgWrap {
    position: relative;
    max-width: 1100px;
    width: 100%;
    .rocket {
      max-width: 1000px;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      bottom: 0;
      img {
        width: 100%;
      }
    }
    .star {
      max-width: 60px;
      position: absolute;
      left: 85%;
      top: 60%;
      video {
        width: 100%;
      }
    }
    .smallStar {
      max-width: 20px;
      position: absolute;
      left: 95%;
      top: 25%;
      video {
        width: 100%;
      }
    }
  }

  .textContainer {
    z-index: 300;
    margin-left: 70px;
    padding-top: 258px;
    display: flex;
    flex-direction: column;

    .halfCircle {
      width: 110px;
      position: absolute;
      left: -35px;
      z-index: -1;
      @media screen and (max-width: 1250px) {
        width: 80px;
      }
      @media screen and (max-width: 1768px) {
        width: 80px;
        top: 20%;
        left: 25%;
      }
      @media screen and (max-width: 1768px) {
        width: 60px;
        left: 0;
      }
    }

    .bitcoin {
      width: 120px;
      position: absolute;
      left: 25%;
      top: 8%;
      z-index: -1;
      @media screen and (max-width: 768px) {
        width: 60px;
        left: 15%;
        top: 3%;
      }
    }

    .title {
      max-width: 100%;
      font-size: 47px;
      background-image: linear-gradient(180deg, #fae485 0%, #f47115 27.51%);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-wrap: nowrap;

      span {
        font-size: 18px;
      }

      @media screen and (max-width: 1140px) {
        font-size: 45px;
      }

      @media screen and (max-width: 960px) {
        font-size: 40px;
      }

      @media screen and (max-width: 768px) {
        font-size: 30px;
        text-wrap: wrap;
      }

      @media screen and (max-width: 550px) {
        font-size: 24px;
      }
    }

    .description {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      margin: 36px 0 36px;
    }

    .description1 {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      margin: 14px 0 0;
    }
    .nftTitle {
      color: #fff;
      font-family: JunkyardCalibo;
      font-size: 26px;
      font-weight: 400;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      .title,
      .description,
      .description1,
      .nftTitle {
        text-align: center;
      }
    }
    .circle {
      width: 110px;
      position: absolute;
      bottom: 28%;
      right: 45%;
      img {
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .nftContainer {
      display: flex;
      gap: 37px;
      margin: 27px 0 32px;

      .nft {
        display: flex;
        flex-direction: column;
        gap: 13px;
        justify-content: start;
        align-items: center;

        img {
          width: 80px;
        }
        div {
          max-width: 106px;
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-weight: 400;
          display: flex;
          text-align: center;
        }

        &:last-child {
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media screen and (max-width: 1096px) {
        gap: 24px;
      }

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;

        .nft {
          width: calc(50% - 18.5px);
        }
      }
    }

    .whitelistSection {
      display: flex;
      gap: 5px;
      margin-bottom: 187px;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }

      @media screen and (max-width: 768px) {
        justify-content: center;
        img {
          width: 20px;
          height: auto;
        }
      }

      .whitelistButton {
        color: #fa8943;
        font-family: Inter;
        font-size: 26px;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: uppercase;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          text-align: center;
        }
      }
    }

    .buttonWrapper {
      position: relative;

      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
      }

      .circle {
        width: 110px;
        position: absolute;
        top: 30%;
        left: 20%;
        z-index: -1;

        @media screen and (max-width: 1250px) {
          width: 90px;
        }
        @media screen and (max-width: 768px) {
          width: 60px;
          left: -22%;
        }
        @media screen and (max-width: 580px) {
          width: 40px;
        }
      }

      button {
        border-radius: 10px;
        border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
        background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
        color: #fff;
        padding: 14px 0;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        max-width: 156px;
        cursor: pointer;
        margin: 30px 0 80px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;

        &:disabled {
          opacity: 0.6;
        }
      }
    }

    @media screen and (max-width: 1250px) {
      margin-left: 0;
      padding-top: 182px;
    }
  }

  @media screen and (max-width: 1366px) {
    .imgWrap {
      .rocket {
        bottom: -139px;
      }
      .star {
        top: 70%;
        width: 40px;
        left: 90%;
      }
      .smallStar {
        top: 40%;
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .imgWrap {
      .rocket {
        display: none;
      }
      .star {
        right: 0;
        left: unset;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .imgWrap {
      margin-bottom: 27px;

      .rocket {
        position: inherit;
        top: 0;
        display: flex;
        align-items: start;
      }
      .star {
        display: none;
      }
      .smallStar {
        display: none;
      }
    }
  }
}
