.assetsWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto hidden;
  align-items: center;
  justify-content: center;

  .asset {
    display: flex;

    div {
      width: 155px;
      height: 65px;
      color: #fff;
      font-weight: 500;
      line-height: 150%;
      font-family: JunkyardCalibo;
      font-size: 16px;
      border: 1px solid #f47115;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      padding: 0 12px;
    }

    :nth-child(2) {
      border-left: none;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 1px;
        background: linear-gradient(to bottom, #fae485, #f47115);
      }
    }

    .numberAsset {
      font-family: Roboto;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 550px) {
    .asset {
      div {
        width: 155px;
        padding: 0 5px;
      }
    }
  }
}
