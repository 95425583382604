.container {
  width: 420px;

  .doughnut {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
      width: 340px;
  }
}
