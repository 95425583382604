.container {
  display: flex;
  padding: 22px 0;
  justify-content: space-between;
  position: fixed;
  margin: 0;
  color: #fff;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);

  &.scrolled {
    background-color: black;
  }

  .wrapper {
    width: 100%;
    max-width: 1740px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2000;
  }

  .socialIcons {
    display: flex;
    column-gap: 20px;
  }

  .logo {
    font-family: Inter;
    font-size: 35px;
    font-weight: 600;
    align-items: center;
    display: flex;
  }
  .logo img {
    height: auto;
    max-width: 146px;
    object-fit: cover;
  }

  .sign {
    display: flex;
    gap: 15px;
    align-items: center;

    button {
      border-radius: 10px;
      border: 1px solid var(--black-black-100, #fff);
      padding: 11px 17px 14px 18px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      font-family: JunkyardCalibo;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
    }

    .signUp {
      background-color: #f3850c;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation {
      display: none;
    }

    .menu img {
      display: inline;
    }
  }
}
