.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #fff;
    font-family: JunkyardCalibo;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 51px 0 129px;

    .logo {
      width: 280px;
      height: 280px;
    }

    .description {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-transform: uppercase;
      margin: 31px 0 21px;
    }

    .whitelistSection {
      display: flex;
      gap: 5px;

      img {
        width: 36px;
        height: 36px;
      }

      .whitelistButton {
        color: #fa8943;
        font-family: Inter;
        font-size: 26px;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .benefitsContainer {
    display: flex;
    align-items: start;
    max-width: 912px;
    gap: 56px;

    .benefit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 13px;

      img {
        width: 90px;
      }

      div {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .benefitsContainer {
      gap: 15px;

      .benefit {
        div {
          max-width: 260px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .benefitsContainer {
      flex-direction: column;
      align-items: center;
      gap: 56px;

      .benefit {
        div {
          max-width: 307px;
          width: 100%;
        }
      }
    }

    .logoContainer {
      margin-bottom: 89px;
      .logo {
        width: 130px;
        height: 130px;
      }

      .whitelistSection {
        img {
          width: 20px;
          height: 20px;
        }

        .whitelistButton {
          font-size: 18px;
        }
      }
    }
  }
}
