.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  padding-top: 100px;

  .connectWallet {
    border-radius: 10px;
    border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
    background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
    color: #fff;
    padding: 11px 17px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: JunkyardCalibo;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }
}

.confirmModalWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  .confirmModal {
    background-color: #000;
    border: 1px solid #fff;
    max-width: 350px;
    padding: 20px;
    .confirmButtons {
      display: flex;
      column-gap: 20px;
      align-items: center;
      .cancel {
        font-weight: bold;
        cursor: pointer;
        width: 160px;
        height: 50px;
        border-radius: 8px;
        border: none;
        color: #fff;
        background-color: #eb5757;
        font-size: 17px;
        transition: 0.2s;
        margin: 20px 0;

        &:hover {
          background-color: #fc4747;
        }
      }
    }
  }
}

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 626px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.circle {
  width: 50px;
  height: 50px;
  border: 2px solid #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.name {
  margin-top: 8px;
}

.line {
  position: absolute;
  top: 30%;
  left: 100%;
  width: 180px;
  height: 2px;
  background-color: #333;
}

.activeCircle {
  background-color: #f47115;
  color: white;
}
.activeName {
  color: #f47115;
}
.activeLine {
  background-color: #f47115;
}

.disconnect {
  font-weight: bold;
  cursor: pointer;
  width: 160px;
  height: 50px;
  border-radius: 8px;
  border: none;
  color: black;
  background-color: #eb5757;
  font-size: 17px;
  opacity: 0.8;
  transition: 0.2s;
  margin: 20px 0;

  &:hover {
    background-color: #fc4747;
  }
}

.verticalStep {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  position: relative;

  .verticalLine {
    position: absolute;
    left: 26px;
    top: 85%;
    height: 27px;
    width: 2px;
    background-color: #333;
  }
}
