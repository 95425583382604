.container {
  background-image: url("../../assets/img/whitelist-bg.webp");
  width: 100%;
  object-fit: cover;

  .overlay {
    position: relative;

    .backButtonContainer {
      width: 100%;
      max-width: 1740px;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
      .back {
        position: fixed;
        top: 120px;
        z-index: 11111;
        color: #bcbcbc;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        cursor: pointer;
        display: flex;
        gap: 6px;
        align-items: center;
        img {
          width: 13px;
          height: 13px;
        }
      }
    }

    .overik {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: 1000;
    }

    .modalContainer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      z-index: 11111;
      padding-bottom: 140px;
      padding-top: 120px;
    }

    .modalWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      overflow: hidden;
      .logo {
        margin-bottom: 10px;
        img {
          width: 152px;
        }
      }
      .logoTitle {
        max-width: 670px;
        text-align: center;
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 20px;
        padding: 0 16px;
      }
      .modal {
        border: 1px solid #fff;
        background: #171717;
        padding: 38px 0 38px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative;
        border-radius: 10px;
        margin-bottom: 34px;
        max-width: 720px;
        width: 99%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .close {
          position: absolute;
          top: 5%;
          right: 2%;
          cursor: pointer;
        }

        .confirmModal {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
          position: relative;
        }

        .title {
          text-align: center;
          font-family: JunkyardCalibo;
          font-size: 24px;
          font-weight: 400;
          color: #f47115;
          margin-bottom: 13px;
        }

        .description {
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          padding: 0 10px;

          span,
          a {
            color: #f47115;
          }
        }

        .inputContainer {
          max-width: 396px;
          width: 100%;
          display: flex;
          gap: 22px;
          margin: 63px 0;

          .inputWrapper {
            max-width: 396px;
            margin: 0 20px;
            position: relative;
            display: flex;

            @media screen and (min-width: 768px) {
              width: 100%;
            }

            input {
              padding: 10px;
              border-radius: 4px;
              border-top: 0.5px solid var(--s, #f5841f);
              border-right: 0.5px solid var(--s, #f5841f);
              border-bottom: 1px solid var(--s, #f5841f);
              border-left: 0.5px solid var(--s, #f5841f);
              background: #171717;
              outline: none;
              color: #fff;
              width: 100%;
            }

            p {
              position: absolute;
              font-family: Roobert-Regular;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #e84142;
              margin: 0;
              top: 110%;
            }
          }
        }

        .navigationButtons {
          display: flex;
          justify-content: center;
          gap: 16px;

          button {
            border-radius: 10px;
            border: 1px solid var(--f, rgba(255, 255, 255, 0.47));
            background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
            color: #fff;
            width: 182px;
            height: 56px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            font-family: JunkyardCalibo;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              background: var(
                --hj,
                linear-gradient(180deg, #a05c26 0%, #ba461a 100%)
              );
              border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
            }

            &:active {
              background: var(
                --s,
                linear-gradient(225deg, #f5841f 14.89%, #fa7714 85.85%)
              );
            }

            &:disabled {
              border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
              opacity: 0.5;
              background: var(
                --btcpad,
                linear-gradient(180deg, #fae485 0%, #f47115 27.51%)
              );
              cursor: not-allowed;
            }
          }
        }
      }

      .progressBar {
        display: flex;

        gap: 38px;
        text-align: center;
        font-family: JunkyardCalibo;
        font-weight: 400;
        color: #f47115;
        max-width: 900px;
        width: 100%;

        .stepWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          row-gap: 21px;
          .progressStep {
            width: 100%;
            height: 10px;
            background-color: #ccc;
            border-radius: 10px;

            .step {
              width: 200px;
              top: 21px;
              left: -5%;
            }
          }
        }
        .progressStep.active {
          background-color: #f59f47;
        }
      }

      @media screen and (max-width: 768px) {
        .logo {
          margin-bottom: 25px;
          img {
            width: 98px;
          }
        }
        .logoTitle {
          max-width: 670px;
          text-align: center;
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 20px;
        }

        .modal {
          padding-top: 59px;
          height: 100%;

          .inputContainer {
            flex-direction: column;
          }

          .title {
            text-align: center;
            font-family: JunkyardCalibo;
            font-size: 20px;
            font-weight: 400;
            color: #f47115;
          }

          .navigationButtons {
            gap: 12px;
            flex-wrap: wrap;
            button {
              width: 140px;
            }
          }
        }

        .progressBar {
          gap: 16px;
          .progressStep {
            .step {
              width: 76px;
              left: 10%;
            }
          }
        }
      }
    }
  }
}
