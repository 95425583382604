@font-face {
  font-family: "junkyardcalibo";
  src: url("./assets/font/junkyardcalibo.woff") format("woff");
}

@font-face {
  font-family: "Signika Negative";
  src: url("./assets/font/SignikaNegative-VariableFont_wght.ttf") format("woff");
}

@font-face {
  font-family: Inter;
  src: url("./assets/font/Inter-Regular.ttf") format("woff");
}

@font-face {
  font-family: Roboto;
  src: url("./assets/font/Roboto-Regular.ttf") format("woff");
}

body {
  margin: 0;
  font-family: junkyardcalibo;
}

.signika {
  font-family: "Signika Negative", "Signika Negative Variable Font";
}

code {
  font-family: junkyardcalibo, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
