.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 111px;
  padding-bottom: 179px;

  .mobile {
    width: 100%;
  }

  .web {
    width: 100%;
    max-width: 912px;
  }

  h2 {
    color: #fff;
    font-family: JunkyardCalibo;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .container {
    max-width: 912px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;

    .slider {
      width: 100%;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .slide {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
      }

      .slick-slider {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ul {
        bottom: -35px;
        li {
          button {
            width: 12px;
            height: 12px;

            &::before {
              color: #fa7714;
              font-size: 16px;
            }
          }
        }
      }
    }

    .road {
      border-bottom: 0.5px solid #cecece;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 23px;

      .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          width: 100%;

          .titleNumber {
            color: #fff;
            font-family: JunkyardCalibo;
            font-size: 16px;
            font-weight: 400;
            width: 50px;
            height: 37px;
            background-color: #fa7714;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .titleText {
            color: #fcfcfc;
            font-family: JunkyardCalibo;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            max-width: 154px;
          }
        }
      }
      .bulletsContainer {
        position: relative;
        margin: 0 135px;

        .bulletWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          width: 100%;
          top: -4px;

          .bullet {
            width: 9px;
            height: 9px;
            background-color: #fa7714;
            border-radius: 50%;
          }
        }
      }
    }

    .cardWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .card {
        display: flex;
        flex-direction: column;
        max-width: 280px;

        .imgWrapper {
          position: relative;
          display: flex;
          align-items: end;

          .logo {
            position: absolute;
            top: 30%;
            left: 37%;
          }
        }

        .descriptionContainer {
          padding: 30px 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          background-color: #1a1b23;
          gap: 32px;

          .description {
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;
            color: #fcfcfc;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .stepImgContainer {
            position: relative;
            img {
              width: 91px;
            }
            div {
              position: absolute;
              top: 35%;
              left: 45%;
              color: #fff;
              font-family: Inter;
              font-size: 24px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 970px) {
    .container {
      .cardWrapper {
        .card {
          max-width: 235px;

          .imgWrapper {
            .yellowBg {
              max-width: 235px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    gap: 65px;
    padding-bottom: 84px;

    .container {
      .cardWrapper {
        .card {
          max-width: 280px;

          .imgWrapper {
            .yellowBg {
              max-width: 280px;
            }
          }

          .descriptionContainer {
            height: 370px;
            padding: 5px 24px 20px;
          }
        }
      }

      .road {
        margin-bottom: 24px;
        gap: 0;

        .titleContainer {
          margin-bottom: 17px;
        }

        .bulletsContainer {
          margin: 0 118px;
          .bulletWrapper {
            width: 9px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .web {
    display: none;
  }
}
